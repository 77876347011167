import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  SelectionState,
  convertFromHTML,
  ContentState,
} from "draft-js";
import "draft-js/dist/Draft.css";

const RTEditor = ({ insertText }) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const appendBlocksFromHtml = (htmlString) => {
    const newBlockMap = convertFromHTML(htmlString);
    const contentState = editorState.getCurrentContent();

    const blockMap = contentState.getBlocksAsArray();
    console.log(blockMap);
    if (blockMap.length === 1 && blockMap[0].text === "") {
      //remove first line if empty;
    } else {
      newBlockMap.contentBlocks = blockMap.concat(newBlockMap.contentBlocks);
    }

    const newContentState = ContentState.createFromBlockArray(
      newBlockMap,
      newBlockMap.entityMap
    );
    const newEditorState = EditorState.createWithContent(newContentState);

    scrollToEnd(newEditorState);
  };

  const scrollToEnd = (state) => {
    const content = state.getCurrentContent();
    const blockMap = content.getBlockMap();

    const key = blockMap.last().getKey();
    const length = blockMap.last().getLength();

    // On Chrome and Safari, calling focus on contenteditable focuses the
    // cursor at the first character. This is something you don't expect when
    // you're clicking on an input element but not directly on a character.
    // Put the cursor back where it was before the blur.
    const selection = new SelectionState({
      anchorKey: key,
      anchorOffset: length,
      focusKey: key,
      focusOffset: length,
      hasFocus: true,
    });

    const newEditorState = EditorState.forceSelection(state, selection);
    setEditorState(newEditorState);
    setTimeout(() => {
      window.getSelection().focusNode.parentElement.scrollIntoView();
      window.getSelection().anchorNode.parentElement.scrollIntoView();
    }, 10);
  };

  useEffect(() => {
    if (insertText.length > 1) {
      appendBlocksFromHtml(insertText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insertText]);

  return (
    <Editor
      editorState={editorState}
      wrapperClassName="demo-wrapper"
      editorClassName="demo-editor"
      onEditorStateChange={setEditorState}
    />
  );
};

export default RTEditor;
