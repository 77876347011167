import React from "react";
import { motion } from "framer-motion";

const RecButton = ({ children, onClick, listening }) => {
  return (
    <motion.button
      onClick={onClick}
      whileHover={{ scale: 1.05 }}
      whileTap={{ sclae: 0.95 }}
      className="rec-btn-container"
    >
      <div className="rec-button">
        <div className={listening ? "innercircle circle active" : "innercircle circle"} id="x"></div>
        <div className="outcircle circle the3 animation31"></div>
        <div className={listening ? "innercircle circle active" : "innercircle circle"}  id="y"></div>
      </div>
      <span className="rec-btn-label">{listening ? "stop" : "start"}</span>
    </motion.button>
  );
};

export default RecButton;
