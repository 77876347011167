import React from "react";
import "./App.css";
import Protect from "react-app-protect";
import Dictaphone from "./components/Dictaphone";

function App() {
  return (
    <>
      <Protect sha512="5A5E8549C7A9AFC5D9C73212F7973E49C1C8B01D06F3990E2C5BFFA08620C42C483CADC721556EE23C3FF876EEC7AAFE44E968361F37C9CEB80B1E6709AD3518">
        <div className="container">
          <h1>Voice Notes</h1>

          <Dictaphone />
        </div>
      </Protect>
    </>
  );
}

export default App;
