import React, { useState, useEffect } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { useStopwatch } from "react-timer-hook";
import RTEditor from "./RTEditor";
import RecButton from "./RecButton";

const Dictaphone = () => {
  const [note, setNote] = useState("");
  const [lastListening, setLastListening] = useState(false);

  const { seconds: timerSeconds, reset: timerReset } = useStopwatch({
    autoStart: true,
  });

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  //   useKeypress(" ", () => {
  //     if (listening) {
  //       SpeechRecognition.stopListening();
  //     } else {
  //       toggleListening();
  //     }
  //   });

  const toggleListening = () => {
    if (listening) {
      SpeechRecognition.stopListening();
    } else {
      SpeechRecognition.startListening({
        continuous: false,
        language: "de-DE",
      });
    }
  };

  useEffect(() => {
    if (lastListening && !listening) {
      if (timerSeconds > 10) {
        setNote("<p>" + transcript + "</p><br>");
      } else {
        setNote(transcript);
      }

      timerReset();

      resetTranscript();
      toggleListening();
    }
    setLastListening(listening);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listening]);



  useEffect(() => {
    toggleListening();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <>
      <div>
        <div className="header">
          <span className="speech-box">{transcript}</span>
          <RecButton onClick={toggleListening} listening={listening} />
        </div>
      </div>
      <div className="box">
        <h2>Notes</h2>
        <RTEditor insertText={note} />
      </div>
    </>
  );
};
export default Dictaphone;
